<template>
	<v-dialog
		persistent
		v-model="splitDialog"
		transition="dialog-bottom-transition"
		overlay=false
		scrollable
		width="800px"
		min-width="700px"
		max-width="900px"
	>
		<v-card>
			<v-card-title class="success white--text">{{ $t('message.createPartialShipment') }}</v-card-title>
			<v-card-text>
				<v-form lazy-validation>
					<v-layout row pt-3>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.originalContract') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1 class="font-weight-bold">{{ Contract__version != null ? [OriginalContract__title, OriginalContract__revision_no , OriginalContract__partial_no].filter(Boolean).join('-') : OriginalContract__title }}</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.otherPartialShipments') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<span>
								<v-chip
									:key="partial.Contract.id"
									class="mr-3"
									pill
									small
									v-for="partial in Contracts__partialShipments"
								>
									{{ [partial.Contract.title, partial.Contract.revision_no , partial.Contract.partial_no].filter(Boolean).join('-') }}
								</v-chip>
							</span>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.partialContract') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<v-select
								:items="partialContractNumbers"
								autocomplete="off"
								dense
								hide-details
								solo
								v-model="Contract__partial_no"
							>
							</v-select>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<v-menu
								:close-on-content-click="false"
								:nudge-right="40"
								max-width="290px"
								min-width="290px"
								ref="contractDateMenu"
								transition="scale-transition"
								v-model="contractDateMenu"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										:label="Contract__contractdate ? '' : $t('message.required')"
										append-icon="event"
										autocomplete="off"
										background-color="white"
										class="force-text-left"
										clearable
										hide-details
										hint="DD/MM/YYYY format"
										persistent-hint
										readonly
										solo
										v-model="computedContractDateFormatted"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="Contract__contractdate"
									@input="contractDateMenu = false"
									first-day-of-week="1"
									locale-first-day-of-year="4"
									show-week
								></v-date-picker>
							</v-menu>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.shippingWeek') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<v-combobox
								:items="sortedCalendarWeeks"
								:label="Contract__shippingweekstatement ? '' : $t('message.required')"
								:return-object="false"
								autocomplete="off"
								clearable
								dense
								hide-details
								item-value="Calendarweek.title"
								item-text="Calendarweek.title"
								solo
								v-model="Contract__shippingweekstatement"
							>
                                <template v-slot:item="{item}">
                                    <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                        <div class="d-flex flex-column" style="width: 100% !important;">
                                            <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                            <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        </div>
                                    </template>
                                    <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                        <template v-if="[52,53].includes(item.Calendarweek.id)">
                                            <div class="d-flex flex-column" style="width: 100% !important;">
                                                <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                <v-divider></v-divider>
                                                <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                        </template>
                                    </template>
                                    <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                        <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                    </template>
                                </template>
							</v-combobox>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.osd') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<v-menu
								ref="originalShippingDateMenu"
								v-model="originalShippingDateMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								max-width="290px"
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										:label="Contract__shipweekdate ? '' : $t('message.required')"
										autocomplete="off"
										append-icon="event"
										background-color="white"
										class="force-text-left"
										clearable
										hide-details
										hint="DD/MM/YYYY format"
										persistent-hint
										readonly
										solo
										v-on="on"
										v-model="computedOriginalShippingDateFormatted"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="Contract__shipweekdate"
									@input="originalShippingDateMenu = false"
									first-day-of-week="1"
									locale-first-day-of-year="4"
									show-week
								></v-date-picker>
							</v-menu>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.numberOfContainers') }}</v-flex>
						<v-flex xs7 lg7 md6 py-1>
							<v-row class="px-3 pt-3">
								<v-col cols="3" class="pa-0 ma-0" v-if="[4,7].includes(Contract__containersize_id) == false">
									<v-text-field
										:value="Contract__containercount"
										autocomplete="off"
										class="force-text-left"
										clearable
										dense
										hide-details
										min="1"
                                        type="number"
										solo
										@change="Contract__containercount = $event"
									>
									</v-text-field>
								</v-col>
								<v-col cols="1" class="pa-0 ma-0 text-center" v-if="[4,7].includes(Contract__containersize_id) == false">
									<v-icon class="mt-2" small>mdi-close</v-icon>
								</v-col>
								<v-col cols="5" class="pa-0 ma-0">
									<v-select
										:items="containerSizes"
										autocomplete="off"
										clearable
										dense
										hide-details
										item-text="Containersize.title"
										item-value="Containersize.id"
										reverse
										solo
										v-model="Contract__containersize_id"
									>
									</v-select>
								</v-col>
							</v-row>
						</v-flex>
					</v-layout>
					<v-layout row>
						<v-col cols="12" class="pr-0">
							<SplitContractSalesItemList></SplitContractSalesItemList>
						</v-col>
					</v-layout>
				</v-form>
			</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn color="default" small @click="splitDialog = false" >{{$t('message.cancel')}}</v-btn>
				<v-btn
					:loading="loading.split"
					color="info"
					small
					@click="addPartialContract"
				>{{ $t('message.proceed') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	// import {textTruncate, getCurrentAppLayout, formatDate} from "Helpers/helpers";
	import {
        formatDate,
        getCurrentAppLayout,
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        getSafe,
        numberFormat,
        textTruncate,
        yearHasExtraWeek
    } from "Helpers/helpers";
	import {mapFields} from 'vuex-map-fields'
	import {mapGetters, mapActions} from "vuex";
	import router from "../../router";
	import {api} from "Api";

    const SplitContractSalesItemList = () => import("./SplitContractSalesItemList");

	export default {
		name: "SplitContract",
		components: {SplitContractSalesItemList},
		props: ['dialog','dialog-closed'],
		data(){
			return {
				contractDateMenu: false,
				dialogs: {
					error:  false,
					error_message: ""
				},
				errors: {
					missing_values: []
				},
				loading: {
					split: false,
				},
				originalShippingDateFormatted: null,
				originalShippingDateMenu: false,
				split_dialog: false,
			}
		},
		computed: {
			...mapFields('contract',{
				Contract__id: 'partial.Contract.id',
				Contract__bank_id: 'partial.Contract.bank_id',
				Contract__buyeraddresstype: 'Contract.buyeraddresstype',
				Contract__currency_id: 'partial.Contract.currency_id',
				Contract__commission_term_id: 'partial.Contract.commission_term_id',
				Contract__commission_term_against_id:'partial.Contract.commission_term_against_id',
				Contract__contractdate: 'partial.Contract.contractdate',
				Contract__containercount: 'partial.Contract.containercount',
				Contract__containersize_id: 'partial.Contract.containersize_id',
				Contract__customer_id: 'partial.Contract.customer_id',
				Contract__customer_pi_no: 'partial.Contract.customer_pi_no',
				Contract__date_signed: 'partial.Contract.date_signed',
				Contract__deliveryaddress_id: 'partial.Contract.deliveryaddress_id',
				Contract__destinatione_id: 'partial.Contract.destinatione_id',
				Contract__footer_notes: 'partial.Contract.footer_notes',
				Contract__fsc: 'partial.Contract.fsc',
				Contract__is_export: 'vContract.is_export',
				Contract__issigned: 'partial.Contract.issigned',
				Contract__isposigned: 'partial.Contract.isposigned',
				Contract__incoterm_id: 'partial.Contract.incoterm_id',
				Contract__incotermport: 'partial.Contract.incotermport',
				Contract__loadingport_id: 'partial.Contract.loadingport_id',
				Contract__marketsegment_id: 'partial.Contract.marketsegment_id',
				Contract__office_id: 'partial.Contract.office_id',
				Contract__partial_no: 'partial.Contract.partial_no',
				Contracts__partialShipments: 'partial.Contract.partialShipments',
				Contract__paymentstatement_id: 'partial.Contract.paymentstatement_id',
				Contract__paymentterm_id: 'partial.Contract.paymentterm_id',
				Contract__productgroup_id: 'partial.Contract.productgroup_id',
				Contract__productorigin_id: 'partial.Contract.productorigin_id',
				Contract__po_date_signed: 'partial.Contract.purchaseorder.date_signed',
				Contract__revision_no: 'partial.Contract.revision_no',
				Contract__salescontact_id: 'partial.Contract.salescontact_id',
				Contract__salestype_id: 'partial.Contract.salestype_id',
				Contract__sellertitle: 'partial.Contract.sellertitle',
				Contract__shippingport_id: 'partial.Contract.shippingport_id',
				Contract__shipweekdate: 'partial.Contract.shipweekdate',
				Contract__shippingweekstatement: 'partial.Contract.shippingweekstatement',
				Contract__showbank: 'partial.Contract.showbank',
				Contract__showsignature: 'partial.Contract.showsignature',
				Contract__stampsignatured: 'partial.Contract.stampsignatured',
				Contract__supplier_id: 'partial.Contract.supplier_id',
				Contract__supplier_pi_no: 'partial.Contract.supplier_pi_no',
				Contract__title: 'partial.Contract.title',
				Contract__version: 'partial.Contract.version',
				Contract__Purchaseorder__id: 'partial.Contract.Purchaseorder.id',
				OriginalContract__id: 'partial.OriginalContract.id',
				OriginalContract__title: 'partial.OriginalContract.title',
				OriginalContract__revision_no: 'partial.OriginalContract.revision_no',
				OriginalContract__partial_no: 'partial.OriginalContract.partial_no',
				copyPurchaseOrder: 'partial.copyPurchaseOrder'
			}),
			...mapGetters([
				'buyerContractOffices',
				'calendarWeeks',
				'certifications',
				'containerSizes',
				'incoterms',
				'offices',
				'officeCountries',
			]),
			computedContractDateFormatted () {
				return formatDate(this.Contract__contractdate)
			},
			computedOriginalShippingDateFormatted () {
				return formatDate(this.Contract__shipweekdate)
			},
			partialContractNumbers: function () {
				let items = []
				items.push({'text': this.$t('message.no'), 'value': null})
				let i
				for (i = 1; i <= 35; i++) {
					let obj = {}
					obj['text'] = '- ' + i
					obj['value'] = i.toString()
					items.push(obj)
				}
				return items
			},
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            },
			splitDialog: {
				get() {
					return this.split_dialog;
				},
				set(value){
					this.split_dialog = value
					if(value == false) {
						this.$emit('dialog-closed')
					}
				}
			},
		},
		methods: {
			...mapActions('contract',{
				createPartialContract: 'createPartialContract',
				resetAllContracts: 'resetAllContracts'
			}),
			addPartialContract () {
				this.loading.split = true
                this.checkContractNumberIsAvailable()
                    .then(response => {
                        if(response == true){
                            this.createPartialContract()
                                .then((response) => {
                                    if (response.status == 'success') {
                                        this.$toast.success(this.$t('message.successes.partialShipmentCreated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        this.loading.split = false
                                        this.resetAllContracts()
                                            .then(() => {
                                                // push new route
                                                router
                                                    .push({
                                                        name: 'update_contract',
                                                        params: {contractId: response.contract_id}
                                                    })
                                                    .catch(err => console.log(err))
                                            })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.partialShipmentNotCreated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        this.loading.split = false
                                    }
                                })
                                .catch(() => {
                                    this.$toast.error(this.$t('message.errors.partialShipmentNotCreated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    this.loading.split = false
                                })
                                .finally(() => {
                                    this.loading.split = false
                                })
                        } else {
                            this.loading.split = false
                            this.$toast.error(this.$t('message.errors.contractNumberTaken'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.loading.split = false
                        this.$toast.error(this.$t('message.errors.contractNumberTaken'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
			},
            checkContractNumberIsAvailable() {
			    let contractNumber = [this.Contract__title, this.Contract__revision_no, this.Contract__partial_no].filter(Boolean).join('')
                return new Promise((resolve, reject) => {
                    api
                        .get('/contracts/number-available/' + encodeURIComponent(encodeURIComponent(contractNumber)))
                        .then(response => {
                            console.log(response.data.status)
                            if (response.data.status == 'success') {
                                resolve(true)
                            } else {
                                resolve(false)
                            }
                        })
                        .catch(() => {
                            resolve(false)
                        })
                })
            },
            getCurrentWeek,
            getCurrentYear,
            getNextYear,
            getDateRangeOfWeek,
            getDateRangeOfWeekWithYear
		},
		watch: {
			dialog(value) {
				this.split_dialog = value
			}
		},
		created() {}
	}
</script>

<style scoped>

</style>